import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import {Link} from "gatsby";

import social from "../images/social-new.png";

const NotFoundPage = () => {
  return (
    <Layout>
        <Container>
            <h1 className="font-serif text-8xl">You're lost!</h1>
            <Link to="/" className="font-mono bg-black text-white px-8 py-4 mt-12 inline-block hover:text-pink transition-colors duration-200 mt-8">Go Home</Link>
            <img src={social} alt="" className="max-w-full hidden" />
        </Container>
    </Layout>
  );
;}

export default NotFoundPage
